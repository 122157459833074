window._ = require('lodash');

try {
    window.$ = window.jQuery = require('jquery');
    require('bootstrap');
    require('admin-lte');
    //require('overlayscrollbars');
    window.Swal = require('sweetalert2');
    //window.moment = require('moment');
    //require('daterangepicker');
} catch (e) {
    console.log(e);
}
